import { ref, computed } from "vue";
import { query } from "gql-query-builder";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES } from "../../utils/APIs";
import { ROUTES } from "../../routes/names";
import { sortBy } from "lodash";
import { PUBLISHED_PROFILES } from "../profiles/list"


export const MOUNTED_ASSET_PROFILE = ref(null);
export const MOUNTED_ASSET_PROFILES = ref(null);
export const MOUNTED_ASSET_PROFILE_DETAILS = ref(null);
export const PROFILE_ID = ref(null);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const fields = [
  "id",
  "uuid",
  "alias",
  "name",
  "shortName",
  "profile",
  "projects",
  "type",
  "status",
  "stage",
  "pinAccuracy",
  "province",
  "nearestTown",
  "primaryCommodities",
  "nonCommercialCommodities",
  "byProductCommodities",
  "significance",
  "countryCode",
  "countryName",
  "companyId",
  "companyName",
  "coordinates",
  { owners: ["id", "companyName", "percentage"] },
  {
    products: [
      "productId",
      "name",
      "commission",
      "status",
      "capacity",
      "unit",
      "grossContained",
      "commodities",
      { grades: ["name", "unit", "value"] },
    ],
  },
];

export const getAssetProfile = async ({ element, uuid }) => {
  const gqlQuery = GQL_QUERIES.GET_SUBSCRIBED_ASSET_PROFILE;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;


  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}/${uuid}`)
    .then(response => {
      MOUNTED_ASSET_PROFILE.value = response;
      inProgress.value = false;
    });
};


// TODO
export const getAssetProfiles = async ({ company, element, country }) => {
  const gqlQuery = GQL_QUERIES.GET_ASSET_PROFILES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {
        input: {
          value: {
            company: company,
            keyword: null,
            element: element,
            primaryCommodity: null,
            byProductCommodity: null,
            nonCommercialCommodity: null,
            country: country,
            status: null,
            stage: null,
            products: [],
            type: null,
            page: {
              current: 1,
              size: 10000,
              sortColumn: null,
              sortOrder: null
            },
          },
          type: gqlQuery.input,
          required: true,
        },
      },
      fields: gqlQuery.fields,
    },
  ]);

  const response = await gqlRequest(payload, reqSignal.value.signal);
  inProgress.value = false;

  MOUNTED_ASSET_PROFILES.value = response.data?.[gqlQuery.operation] || {};
};

export const getAssetProfileDetails = async (uuid) => {
  const gqlQuery = GQL_QUERIES.GET_ASSET_PROFILE;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {
        uuid: { value: uuid, type: 'String!' },
      },
      fields: [
        "OEMSuppliers",
        "accessControls",
        "alternativeNames",
        "assignedTo",
        "attachmentLinks",
        "byProductCommodities",
        "changes",
        "commissioned",
        "commodities",
        "coordinates",
        "costed",
        "country",
        "countryName",
        "created",
        "createdBy",
        "editedBy",
        "feedStock",
        "geologicalRegion",
        "id",
        "installationType",
        "installationYear",
        "lom",
        "modified",
        "name",
        "nameShort",
        "nearestTown",
        "nonCommercialCommodities",
        "operatorId",
        "operatorName",
        "pinAccuracy",
        "profile",
        "projects",
        "province",
        "reservesNotes",
        "reservesTotalTons",
        "reservesTotalTonsUnit",
        "resourcesNotes",
        "resourcesTotalTons",
        "resourcesTotalTonsUnit",
        "sourceLinks",
        "stage",
        "status",
        "streamType",
        "totalTonsIndicated",
        "totalTonsInferred",
        "totalTonsMeasured",
        "totalTonsProbable",
        "totalTonsProven",
        "totalTonsReserves",
        "totalTonsResources",
        "type",
        "uuid",
        "versions"
      ]

      // fields: gqlQuery.fields,
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  inProgress.value = false;

  MOUNTED_ASSET_PROFILE_DETAILS.value = response.data?.[gqlQuery.operation] || {};
};

export const ASSET_STATUS_OPTS = computed(() => {
  const tmp = [
    ...new Set(
      (MOUNTED_ASSET_PROFILES.value?.profiles || []).map((nth) => nth.status)
    ),
  ];
  const opts = tmp
    .filter((status) => status?.length > 0)
    .map((status) => {
      return {
        label: status,
        value: status,
      };
    });
  return sortBy(opts, "label");
})

export const mountProfile = async (
  router,
  { profile, element, uuid = null } = {}
) => {
  MOUNTED_ASSET_PROFILE.value = profile;
  router.push({
    name: ROUTES.ASSET_PROFILE.name,
    params: { id: profile?.uuid || uuid },
    query: { element },
  });
};

// Countries that have assets //
export const ASSET_COUNTRIES_OPTS = computed(() => {
  let opts = (PUBLISHED_PROFILES?.value?.assetProfiles || [])
    .map(x => ({
      label: x.countryName,
      value: x.countryCode
    }));

  let distinctObjects = [
    ...new Map((opts || []).map(item => [JSON.stringify(item), item])).values()
  ];

  let sorted = sortBy(distinctObjects, "label");
  return sorted;
});

export const ASSET_COMPANIES_OPTS = computed(() => {
  const companies = [
    ...new Map(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => [
        nth.companyId,
        nth,
      ])
    ).values(),
  ];
  const opts = companies
    .filter((nth) => nth.companyId && nth.companyName !== "")
    .map((nth) => {
      return {
        label: nth.companyName,
        value: nth.companyId,
      };
    });
  return sortBy(opts, "label");
});


export const ASSET_ASSETS_OPTS = computed(() => {
  const assets = [
    ...new Map(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => [
        nth.uuid,
        nth,
      ])
    ).values(),
  ];
  const opts = assets.map((nth) => {
    return {
      label: nth.shortName || nth.name,
      value: nth.uuid,
    };
  });
  return sortBy(opts, "label");
});


export const ASSET_STAGES_OPTS = computed(() => {
  const tmp = [
    ...new Set(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => nth.stage)
    ),
  ];
  const opts = tmp
    .filter((stage) => stage?.length > 0)
    .map((stage) => {
      return {
        label: stage,
        value: stage,
      };
    });
  return sortBy(opts, "label");
});

export const ASSET_TYPES_OPTS = computed(() => {
  const tmp = [
    ...new Set(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => nth.type)
    ),
  ];
  const opts = tmp
    .filter((type) => type?.length > 0)
    .map((type) => {
      return {
        label: type,
        value: type,
      };
    });
  return sortBy(opts, "label");
});

export const ASSET_PRODUCT_GROUP_OPTS = computed(() => {
  let opts = PUBLISHED_PROFILES.value?.productGroups?.filter(p => p.group).map(pg => {
    return {
      label: pg.group
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\b\w/g, char => char.toUpperCase()),
      value: pg.group,
    };
  })
  return sortBy(opts, "label");
});

export const ASSET_PRODUCT_OPTS = computed(() => {
  const tmp = {};
  for (const profile of PUBLISHED_PROFILES.value?.assetProfiles || []) {
    for (const prod of profile?.products || []) {
      tmp[prod.productId] = {
        label: prod.productName,
        value: prod.productId,
      };
    }
  }
  const opts = Object.values(tmp);
  return sortBy(opts, "label");
});