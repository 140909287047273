import { computed, reactive, ref, watch } from "vue";
import { query } from "gql-query-builder";
import { sortBy } from "lodash";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES } from "../utils/APIs";


export const SELECTED_ELEMENT_TO_MAP = ref("*");
export const PROFILE_MARKS = ref([]);
export const MAP_ELEMENTS = ref([]);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());
export const MAP_ELEMENT_OPTS = [
  { label: "Critical Materials", value: "*" },
  { label: "Chromium", value: "Cr" },
  { label: "Cobalt", value: "Co" },
  { label: "Fluorspar", value: "F" },
  { label: "Gallium", value: "Ga" },
  { label: "Graphite", value: "Graphite" },
  { label: "Iron", value: "Fe" },
  { label: "Lithium", value: "Li" },
  { label: "Manganese", value: "Mn" },
  { label: "Molybdenum", value: "Mo" },
  { label: "Nickel", value: "Ni" },
  { label: "Niobium", value: "Nb" },
  { label: "Scandium", value: "Sc" },
  { label: "Vanadium", value: "V" },
  { label: "Silicon", value: "Si" },
  { label: "REE", value: "REE" },
  { label: "Salt", value: "SALT" },
  { label: "Magnesium", value: "Mg" },
  { label: "Tantalum", value: "Ta" },
  { label: "Tin", value: "Sn" },
  { label: "Titanium", value: "Ti" },
  { label: "Tungsten", value: "W" },
];

export const getMapData = async (el) => {
  const gqlQuery = GQL_QUERIES.GET_MAP_DATA;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
 
  
  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}/${el}`)
  .then(response => {
    PROFILE_MARKS.value = response.marks;
    MAP_ELEMENTS.value = response.elements;
    inProgress.value = false;
  });
  
};

watch(SELECTED_ELEMENT_TO_MAP, async (element) => {
  await getMapData(element);
});
