import { ref, computed } from "vue";

export const tableData = ref([]);

export const sortTableColumn = (sorter) => {
  const { columnKey, order } = sorter;

  // if (!order) {
  //   tableData.value = [...props.utils.DATA.value];
  //   return;
  // }

  tableData.value?.sort((a, b) => {
    const valueA = a[columnKey];
    const valueB = b[columnKey];

    if (valueA == null || valueB == null) {
      return valueA == null
        ? order === "ascend"
          ? 1
          : -1
        : order === "ascend"
        ? -1
        : 1;
    }

    //Boolean comparison
    if (typeof valueA === "boolean") {
      return order === "ascend" ? valueA - valueB : valueB - valueA;
    }

    //String and Date comparisons
    if (typeof valueA === "string") {
      // Check if the string is in ISO 8601 date format
      const isISODateA = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(valueA);
      const isISODateB = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(valueB);

      if (isISODateA && isISODateB) {
        // Compare as ISO dates
        const dateA = new Date(valueA).getTime();
        const dateB = new Date(valueB).getTime();
        return order === "ascend" ? dateA - dateB : dateB - dateA;
      }

      // Stricter check for non-ISO date formats (e.g., "MM/DD/YYYY", "YYYY-MM-DD")
      const isStrictDateA = /^\d{4}-\d{2}-\d{2}$/.test(valueA);
      const isStrictDateB = /^\d{4}-\d{2}-\d{2}$/.test(valueB);

      if (isStrictDateA && isStrictDateB) {
        // Compare as dates
        const dateA = new Date(valueA).getTime();
        const dateB = new Date(valueB).getTime();
        return order === "ascend" ? dateA - dateB : dateB - dateA;
      }

      // Compare as strings
      return order === "ascend"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }

    //number comparison
    if (typeof valueA === "number") {
      return order === "ascend" ? valueA - valueB : valueB - valueA;
    }

    return 0;
  });
};
