<template>
  <div
    class="d-flex align-items-center text-xs font-weight-bold"
    v-if="props.text"
  >
    <NIcon :component="icons[props.text]" :size="20" class="me-1" />
    <span>{{ props.text }}</span>
  </div>
</template>

<script setup>
import {
  Diamond,
  BuildingSkyscraper,
  BuildingFactory,
  Flame,
  Recycle
} from "@vicons/tabler";
import { NIcon } from "naive-ui";

const props = defineProps({
  text: {
    type: String,
    default: null,
  },
});

const icons = {
  Mine: Diamond,
  "Tailings/Slag": Flame,
  Refinery: BuildingSkyscraper,
  Factory: BuildingFactory,
  Recycler: Recycle
};
</script>
