<template>
  <!-- <div class="align-items-center"><img src="/images/proximaHeaderImage.png" alt=""></div> -->
    <a
      href="javascript:;"
      class="p-0 nav-link text-white mobile-only"
      :class="[NAVBAR_SHOW_NAVITEMS_MENU ? 'show' : '']"
      id="navItemsMenuButton"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click="NAVBAR_SHOW_NAVITEMS_MENU = !NAVBAR_SHOW_NAVITEMS_MENU"
    >
      <i class="cursor-pointer fa fa-solid fa-bars fa-lg"></i>
    </a>
    <ul class="dropdown-menu dropdown-menu-end me-sm-n4 me-md-n3 p-2 mobile-only"
      ref="popupWindow"
      :class="
        NAVBAR_SHOW_NAVITEMS_MENU ? 'show' : ''
      "
      aria-labelledby="navItemsMenuButton">
      <div style="max-height: 500px; overflow-y: auto">
      <li
        v-for="(menu, key) in menuOptions"
        :key="key"
        class="nav-item   proxima-secondary"
      >
        <SidenavItem
          :url="menu.routeName"
          :class="isActiveMenu(menu)"
          :disabled="menu.disabled"
        >
          <template v-slot:text>
            <img :src="menu.icon" alt="homeIcon" style="width:20px"/>
            {{ menu.label }}
            <span
              v-if="menu.disabled"
              class="badge badge-info text-capitalize text-xxs p-1"
              >{{ $t("App.sidebarMenu.comingSoon") }}</span
            >
          </template>
        </SidenavItem>
      </li> 
    </div>
      </ul>
  </template>
  
  <script setup>
  import { ref, onMounted, onBeforeUnmount } from "vue";
  import { onClickOutside } from "@vueuse/core";
  import { NAVBAR_SHOW_NAVITEMS_MENU } from "../../composables/generics";
  import { ROUTES } from "../../routes/names";
  import SidenavItem from "../Sidenav/SidenavItem.vue";
  import { useI18n } from "vue-i18n";
  import { useRoute } from "vue-router";

  const popupWindow = ref(null);
  const { t } = useI18n();
  const route = useRoute();
  
  onClickOutside(
    popupWindow,
    (event) => (NAVBAR_SHOW_NAVITEMS_MENU.value = false)
  );

  onMounted(() => {
    window.addEventListener("resize", handleResize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", handleResize);
  });

  const handleResize = () => {
  if (window.innerWidth >= 1200) {
    NAVBAR_SHOW_NAVITEMS_MENU.value = false;
  }
};

  const menuOptions = [
  {
    label: t("App.sidebarMenu.dashboardPage"),
    routeName: ROUTES.DASHBOARD.name,
    icon: require("../../../public/images/sideMenuIcons/home_blue.svg"),
    disabled: false,
  },
  {
    label: t("App.sidebarMenu.subscriptionsPage"),
    routeName: ROUTES.FILES.name,
    icon: require("../../../public/images/sideMenuIcons/dashboard_blue.svg"),
    disabled: false,
  },
  {
    label: t("App.sidebarMenu.proximaPage"),
    routeName: ROUTES.PROXIMA_PROFILES.name,
    icon: require("../../../public/images/sideMenuIcons/assetProfiles_blue.svg"),
    disabled: false,
  },
  {
    label: t("App.sidebarMenu.costAnalysis"),
    routeName: ROUTES.COST_ANALYSIS.name,
    icon: require("../../../public/images/sideMenuIcons/costAnalysis_blue.svg"),
    disabled: false,
  },
  {
    label: t("App.sidebarMenu.marketData"),
    routeName: ROUTES.MARKET_DATA.name,
    icon: require("../../../public/images/sideMenuIcons/marketData_grey.svg"),
    disabled: true,
  },
  // {
  //   label: t("App.sidebarMenu.support"),
  //   routeName: ROUTES.SUPPORT.name,
  //   icon: require("../../../public/images/sideMenuIcons/support_blue.svg"),
  //   disabled: false,
  // },
];
const isActiveMenu = (menu) => {
  if(route.name === menu.routeName){
    return "active";
  }
  if(menu.disabled){
    return "disabled-menu";
  }
};
  </script>
  
<style lang="scss" scoped>
.mobile-only {
  display: none;
}

@media (width < 1200px) {
  .mobile-only {
    display: inline-block; /* Or block depending on layout */
  }
}

.badge-info {
  background-color: #F2AB26;
  color: white;
}

/* Show elements only on mobile screens */
@media (width < 1200px) {
  .mobile-only {
    display: inline-block; /* Or block depending on layout */
  }
}
</style>
