<template>
  <NDrawer v-model:show="NOTES_WINDOW" :width="502" placement="right">
    <NDrawerContent :title="$t('NotesWindow.title')">
      <NGrid y-gap="30" :cols="1">
        <NGridItem>
          <NTimeline>
            <NTimelineItem v-for="nth in NOTES_OF_PAGE" :key="nth" type="info" :title="nth.title" line-type="dashed"
              style="cursor: pointer" @click="goToProfile(nth)" :time="nth.created">
              <NGrid y-gap="10" :cols="1">
                <NGridItem>
                  <div class="paragraph">
                    {{ nth.notes }}
                  </div>
                </NGridItem>
                <NGridItem v-if="nth.taggedPersons?.length">
                  <NSpace size="small">
                    <NTag v-for="email in nth.taggedPersons" :key="email" :bordered="false" type="info" size="small">
                      {{ email }}
                    </NTag>
                  </NSpace>
                </NGridItem>
              </NGrid>
              <template #footer>
                {{ nth.created }} |
                <NButton
                  text
                  :loading="inProgress"
                  :disabled="inProgress"
                  size="small"
                  color="#767c82"
                  @click="(event) => openDeleteModal(nth.id, event)"
                  >
                  {{ $t("NotesWindow.deleteButton") }}
                </NButton>
                <NModal
                  v-model:show="showDeleteModal.isVisible"
                  preset="dialog"
                  title="Confirm Deletion"
                > 
                  <template #icon>
                    <div style="display: flex; align-items: center; gap: 8px;">
                      <NIcon size="24" color="#192E40">
                        <Trash />
                      </NIcon>
                    </div>
                  </template>
                  {{ $t("NotesWindow.deleteLabel") }}
                  <template #action>
                    <NButton
                      ghost
                      size="small"
                      color="#767c82"
                      @click="closeDeleteModal"
                    >
                      {{ $t("NotesWindow.cancelButton") }}
                    </NButton>
                    <NButton
                      size="small"
                      color="#192E40"
                      @click="confirmDelete"
                      :loading="inProgress"
                    >
                      {{ $t("NotesWindow.confirmButton") }}
                    </NButton>
                  </template>
                </NModal>
              </template>
            </NTimelineItem>
          </NTimeline>
        </NGridItem>
        <NGridItem v-if="profileType && SHOW_ALL_NOTES === false">
          <NSpace vertical class="space-bottom">
            <NInput v-model:value="NEW_NOTE.title" type="text" :placeholder="$t('Notes.titlePlaceholder')"
              :loading="inProgress" />
            <NInput v-model:value="NEW_NOTE.notes" type="textarea" :placeholder="$t('Notes.notesPlaceholder')"
              :loading="inProgress" />
            <NSpace justify="end">
              <NButton type="default" @click="clearNote" style="width: 100%" :loading="inProgress">
                <template #icon>
                  <NIcon>
                    <X />
                  </NIcon>
                </template>
                {{ $t("Notes.cancelButton") }}
              </NButton>
              <NButton type="info" @click="sendNote" style="width: 100%" :loading="inProgress">
                <template #icon>
                  <NIcon>
                    <BrandTelegram />
                  </NIcon>
                </template>
                {{ $t("Notes.saveButton") }}
              </NButton>
            </NSpace>
          </NSpace>
        </NGridItem>
      </NGrid>
    </NDrawerContent>
  </NDrawer>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import {
  NDrawer,
  NDrawerContent,
  NGrid,
  NGridItem,
  NTimeline,
  NTimelineItem,
  NSpace,
  NTag,
  NInput,
  NIcon,
  NSelect,
  NButton,
  NPopover,
  NModal
} from "naive-ui";
import { BrandTelegram, X, Trash } from "@vicons/tabler";
import { computed, nextTick, onMounted, reactive, watch } from "vue";
import {
  getUserNotes,
  USER_NOTES,
  NOTES_WINDOW,
  SHOW_ALL_NOTES,
} from "../../composables/notes/list";
import {
  updateOrCreateNote,
  deleteNote as _deleteNote,
  mountNote,
  unMountNote,
  inProgress,
  MOUNTED_USER_NOTE,
} from "../../composables/notes/note";
import { ROUTES } from "../../routes/names";
import { toRelativeTime } from "../../utils";
import { PUBLISHED_PROFILES } from "../../composables/profiles/list";
import { MOUNTED_ASSET_PROFILE } from "../../composables/profiles/asset";
import { MOUNTED_COMPANY_PROFILE } from "../../composables/profiles/company";
import { MOUNTED_COUNTRY_PROFILE } from "../../composables/profiles/country";

const router = useRouter();
const route = useRoute();
const NEW_NOTE = reactive({
  title: null,
  notes: null,
});
const showDeleteModal = reactive({
  isVisible: false,
  noteId: null,
});

const openDeleteModal = (id, event) => {
  if (event) event.stopPropagation(); // Stop the event from bubbling up
  showDeleteModal.isVisible = true;
  showDeleteModal.noteId = id;
};

const closeDeleteModal = () => {
  showDeleteModal.isVisible = false;
};

const confirmDelete = async () => {
  if (showDeleteModal.noteId) {
    await deleteNote(showDeleteModal.noteId);
  }
  closeDeleteModal();
  NOTES_WINDOW.value = false;
};

const NOTES_OF_PAGE = computed(() => {
  if (SHOW_ALL_NOTES.value) {
    return USER_NOTES.value?.data || [];
  }
  if (
    route.name === ROUTES.ASSET_PROFILE.name &&
    route.params.id
  ) {
    let notes = (USER_NOTES.value?.data || []).filter(
      (nth) =>
        nth.profileType === "ASSET" &&
        nth.profileUuid === route.params.id
    );
    return notes;
  } else if (
    route.name === ROUTES.COMPANY_PROFILE.name &&
    route.params.id
  ) {
    return (USER_NOTES.value?.data || []).filter(
      (nth) =>
        nth.profileType === "COMPANY" &&
        nth.profileUuid === route.params.id
    );
  } else if (
    route.name === ROUTES.COUNTRY_PROFILE.name &&
    route.params.id
  ) {
    return (USER_NOTES.value?.data || []).filter(
      (nth) =>
        nth.profileType === "COUNTRY" &&
        nth.profileUuid === route.params.id
    );
  } else return USER_NOTES.value?.data || [];
});

const profileType = computed(() => {
  let profileType = null;
  if (ROUTES.ASSET_PROFILE.name === route.name) profileType = "ASSET";
  else if (ROUTES.COMPANY_PROFILE.name === route.name) profileType = "COMPANY";
  else if (ROUTES.COUNTRY_PROFILE.name === route.name) profileType = "COUNTRY";
  return profileType;
});

onMounted(async () => {
  await getUserNotes();
});

// const goToEdit = (note) => {
//   router.push({ name: ROUTES.NOTE.name, params: { id: note.id } });
//   NOTES_WINDOW.value = false;
// };

watch(NOTES_WINDOW, (show) => {
  nextTick(() => {
    if (!show) {
      NEW_NOTE.title = null;
      NEW_NOTE.notes = null;
    } else {
      if (
        route.name === ROUTES.ASSET_PROFILE.name &&
        route.params.id &&
        route.query.element
      ) {
        NEW_NOTE.title =
          route.query.element +
          " - " +
          (MOUNTED_ASSET_PROFILE.value?.name ||
            MOUNTED_ASSET_PROFILE.value?.shortName);
      } else if (
        route.name === ROUTES.COMPANY_PROFILE.name &&
        route.params.id &&
        route.query.element
      ) {
        NEW_NOTE.title =
          route.query.element +
          " - " +
          MOUNTED_COMPANY_PROFILE.value?.companyName ||
          MOUNTED_COMPANY_PROFILE.value?.alias;
      } else if (
        route.name === ROUTES.COUNTRY_PROFILE.name &&
        route.params.id &&
        route.query.element
      ) {
        NEW_NOTE.title =
          route.query.element +
          " - " +
          MOUNTED_COUNTRY_PROFILE.value?.countryName ||
          MOUNTED_COUNTRY_PROFILE.value?.alias;
      } else NEW_NOTE.title = null;
    }
  });
});

const goToProfile = (nth) => {
  if (NOTES_WINDOW.value && SHOW_ALL_NOTES.value) {
    NOTES_WINDOW.value = false;
    SHOW_ALL_NOTES.value = false;
  }

  nextTick(() => {
    let routeName = null;
    if (nth.profileType === "ASSET") routeName = ROUTES.ASSET_PROFILE.name;
    else if (nth.profileType === "COMPANY")
      routeName = ROUTES.COMPANY_PROFILE.name;
    else if (nth.profileType === "COUNTRY")
      routeName = ROUTES.COUNTRY_PROFILE.name;

    if (routeName !== route.name) {
      router.push({
        name: routeName,
        params: { id: nth.profileUUID },
        query: { element: nth.element },
      });
      NOTES_WINDOW.value = false;
      SHOW_ALL_NOTES.value = false;
    } else {
      clearNote();
      mountNote(nth);
      NEW_NOTE.title = nth.title;
      NEW_NOTE.notes = nth.notes;
    }
  });
};

const clearNote = () => {
  unMountNote();
  NEW_NOTE.title = null;
  NEW_NOTE.notes = null;
};

const sendNote = async () => {
  mountNote({
    id: MOUNTED_USER_NOTE.id,
    title: NEW_NOTE.title,
    element: route.query.element,
    profileUUID: route.params.id,
    profileType: profileType.value,
    taggedPersons: [],
    note: NEW_NOTE.notes,
    type: "PERSONAL",
    subscriptionPackageId: null,
  });
  await updateOrCreateNote();
  unMountNote();
  NEW_NOTE.title = null;
  NEW_NOTE.notes = null;
  await getUserNotes();
};

const deleteNote = async (id) => {
  await _deleteNote(id);
  await getUserNotes();
  clearNote();
  NOTES_WINDOW.value = false;
};
</script>
