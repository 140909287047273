export const publicFolder = "https://driqq8crwypb5.cloudfront.net/public-images"

export const getXSmallImage = (image) => {
    return getSizedImage(image, "xsmall");
};

export const getSmallImage = (image) => {
    return getSizedImage(image, "small");
};

export const getSizedImage = (image, size) => {
    const img = image.replace(/^https:\/\/driqq8crwypb5\.cloudfront\.net/, publicFolder)
    const dotIndex = img.lastIndexOf(".");
    if (dotIndex === -1) return img; // No extension found
    const name = img.substring(0, dotIndex);
    const extension = img.substring(dotIndex);
    return `${name}_${size}${extension}`;
};

