import { ref } from "vue";
import { query } from "gql-query-builder";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES } from "../../utils/APIs";

export const NOTES_WINDOW = ref(false);
export const SHOW_ALL_NOTES = ref(false);
export const USER_NOTES = ref({});
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const has_notes = (type, route) => {
  return (USER_NOTES.value?.data || []).filter(
    (nth) =>
      nth.profileType === type &&
      nth.profileUuid === route.params.id
  ).length;
};

export const getUserNotes = async () => {
  const gqlQuery = GQL_QUERIES.GET_USER_NOTES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  
  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}`)
  .then(response => {
    USER_NOTES.value  = response;
    inProgress.value = false;
  });
};
